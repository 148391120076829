.listItem {
  width: fit-content;
}

.typer {
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  animation: typing;
}

.blink::after {
  content: '_';
  opacity: 0%;
  animation: 1s blinker step-end infinite;
}

@keyframes blinker {
  50% {
    opacity: 100%;
  }
}

@keyframes typing {
  to {
    width: 100%;
  }
}
