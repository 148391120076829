.footer {
  height: 50px;
  width: calc(100% + 50px);
  margin-left: -25px;
  border-top: 3px solid #fff;
  position: sticky;
  z-index: 100;
  bottom: 0;
  display: flex;
  align-items: center;

  padding: 0 20px;
  grid-area: 5 / 1 / 6 / 9;
}

.footer a > img {
  height: 1.5rem;
  translate: -12.5px;
}

.container {
  position: relative;
  left: calc(50% - 40px);
  display: flex;
  gap: 10px;
}

.switch {
  left: calc(100% - 190px);
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 22px;
  bottom: 2px;
  box-shadow: none;
  background-color: white;
  border-radius: 50%;
  transition: .3s;
  box-shadow: inset 4.5px -0px 0px 0px #fff;
}

input:checked + .slider:before {
  background-color: transparent;
  transform: translateX(-20px);
}

