.typer {
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  animation: typing;
}

@keyframes typing {
  to {
    width: 100%;
  }
}
