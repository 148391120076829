.header {
  height: 50px;
  width: calc(100% + 50px);
  margin-left: -25px;
  padding: 0 25px;
  border-bottom: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 9;
}

.header > p {
  margin: 0;
  font-size: 1.1rem;
}

.windowName {
  flex-grow:1;
  text-align:center;
  translate: 100px;
}

@media (max-width: 786px) {
  .windowName {
    text-align: unset;
    justify-content: space-between;
    translate: 0;
  }
}

@media (max-width: 520px) {
  .header > p {
    font-size: 1rem;
  }
}

@media (max-width: 430px) {
  .header > p {
    font-weight: bold;
  }

  .header > .windowName {
    font-size: .7rem;
  }

  .header > .date {
    font-size: .7rem;
  }
}