.background {
  background: #000000;
}

.glas {
  background: #ffffff08;
}

@media (max-width: 1160px) {
  .glas {
    backdrop-filter: blur(3px);
  }
}