* {
  box-sizing: border-box;
  font-family: monospace;
  color: #fff;
}

body {
  height: 100vh;
  margin: 0;
}

p,
h1 {
  font-size: 2rem;
  letter-spacing: 3px;
  margin: 2rem 0;
}

h1 {
  font-size: 3rem;
}

a {
  text-decoration: none;
  color: #fff;
}

ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  width: fit-content;
}

img {
  height: 2rem;
  width: 2rem;
}

#root {
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 2 / 1 / 3 / 4;
  padding-top: 25px;
}

.containerLinks {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 25px;
}

.containerRechts {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerLinks::-webkit-scrollbar,
.containerRechts::-webkit-scrollbar {
  display: none;
}

.margin {
  margin-left: 100px;
}

.listElement {
  padding-bottom: 2rem;
}

.listElement:last-of-type {
  padding-bottom: 1rem;
}

.matrix {
  background: transparent;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

@media (max-width: 1160px) {
  .containerRechts {
    display: none;
  }
}

@media (max-width: 786px) {
  p {
    font-size: 1.2rem;
    margin: 1.2rem 0;
  }

  img {
    height: 1.2rem;
  }

  .margin {
    margin-left: 75px;
  }
}

@media (max-width: 600px) {
  p {
    font-size: 1rem;
    margin: 1rem 0;
  }

  img {
    height: 1rem;
  }

  .margin {
    margin-left: 50px;
  }
}

@media (max-width: 520px) {
  p {
    font-size: 0.7rem;
    margin: 0.7rem 0;
    font-weight: bold;
  }

  img {
    height: 0.8rem;
  }

  .margin {
    margin-left: 25px;
  }

}