.background {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glas {
  height: 90%;
  width: 90%;
  display: grid;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 50px 1fr 50px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0 25px 0 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  backdrop-filter: blur(8px);
  border-radius: 25px;
  border: #fff solid 4px;
}

@media (max-width: 1400px) {
  .glas {
    grid-template-columns: 74% 26%;
  }
}

@media (max-width: 1160px) {
  .glas {
    grid-template-columns: 100% 0%;
  }
}

@media (max-width: 520px) {
  .glas {
    height: 80%;
    width: 100%;
    border-radius: 0;
    border: none;
    border-top: #fff solid 4px;
    border-bottom: #fff solid 4px;
    transform: translate(0, -2%);
  }
}
